import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import analysisCard from "../ToolsCard.vue";
import Button from "@/components/button";
import Input from "@/components/input";
import Table from "@/components/table";
import Tab from "@/components/tab";
import NeoTabsSwitch from "@/components/tabs-switch";
import Loader from "@/components/loader";
import DropdownAnalysis from "@/components/dropdown-analysis";
import VueMultiselect from "@/components/vue-multiselect";
import "@/assets/css/multiselect.scss";
import Pagination from "@/components/pagination";
import axios from "@/axios/osint.js";
import rawAxios from "@/axios";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {setUsernamesDropdownData, convertToCSV, csvFileName} from "../../../../utils/functions";
import NeoAnalyse from "@/components/analyse";
import JsonCSV from "vue-json-csv";
import {monitoringSearchData, getBackgroundStatus, getResultsID} from "@/utils/monitoringTools.js";
import toolsHelper from "@/mixins/toolsHelper";
import neoSwitch from "@/components/toggle-switch";
import {DateTime} from "luxon";

export default {
    name: "neo-gitlab",
    components: {
        "neo-input": Input,
        "neo-button": Button,
        "neo-table": Table,
        "neo-tab": Tab,
        "neo-tabs-switch": NeoTabsSwitch,
        "neo-pagination": Pagination,
        "font-awesome-icon": FontAwesomeIcon,
        "neo-loader": Loader,
        "neo-dropdown-analysis": DropdownAnalysis,
        VueMultiselect,
        "analysis-card": analysisCard,
        NeoAnalyse,
        "download-csv": JsonCSV,
        neoSwitch,
    },
    mixins: [toolsHelper],
    props: {
        toolData: {
            type: Array,
            default: () => [],
        },
        tool: {
            type: String,
            default: "",
        },
        toolSearchQueries: Array,
    },
    data() {
        return {
            loaderController: {
                gitlab: {
                    loading: false,
                    success: false,
                    filterLoading: false,
                    filterSuccess: false,
                },
            },
            allGitlab: [],
            csvData: {
                gitlab: [],
            },
            gitlabUsers: [],
            alertMessage: {
                success: null,
                error: null,
            },
            case_id: "",
            headers: [
                {
                    text: "",
                    value: "details",
                },
            ],
            itemKey: "username",
            searchCaseFilter: "",
            selectable: false,
            hasAction: false,
            searchKeyword: "",
            selectedItem: null,
            showResult: false,
            selectVal: "",
            searchClicked: false,
            tabName: "",
            request_id: "",
        };
    },
    computed: {
        ...mapGetters(["getReadOnlyMode", "getResearchData", "getAllToolsData", "getAllMonitoringData", "getCaseEmails", "getMonitoringDateFilter", "getSelectedToolQuery", "getProduct"]),

        fetchAllData() {
            if (this.getResearchData.personalDetails?.length || this.getResearchData.socialProfiles?.length) {
                this.getToolData();
            }
        },

        csvFields() {
            return ["avatar_url", "name", "username"];
        },
        isMonitoringTab() {
            return this.$route.name === "monitoring";
        },
        checkMarkAllDone() {
            if (this.allGitlab && this.allGitlab.length) return this.allGitlab.every((result) => result.changes_viewed);
            else return false;
        },
    },
    watch: {
        "$route.query.q"(to, from) {
            if (to !== from && to !== this.searchKeyword) {
                this.redirectToQuery();
            }
        },

        // selectedItem: {
        //     handler(val) {
        //         this.showResult = false;
        //     },
        //     deep: true,
        // },
    },
    async mounted() {
        this.case_id = this.$route.params.id;
        this.tabName = this.$route.name;
        const dataRes = this.getToolsData(this.tabName, "gitlab-tool");
        if (dataRes) this.redirectToQuery(dataRes);
        this.getToolData();
    },
    beforeDestroy() {
        // this.SET_ALL_TOOLS_DATA({ ...this._data, "tool-name": "gitlab-tool" });
        this.setToolsData(this.tabName, "gitlab-tool");
    },
    methods: {
        getBackgroundStatus,
        getResultsID,
        ...mapMutations(["SET_ALL_TOOLS_DATA", "SET_ALL_MONITORING_DATA"]),
        ...mapActions(["getAllCaseEmails"]),
        async getToolData() {
            this.gitlabUsers = await this.getDropdownData("email");
        },

        async getDropdownData(field) {
            let data = this.toolData.filter((user) => user.val_type === field);
            return data;
        },

        getOptionsList(list) {
            return [
                {
                    group: "Email",
                    categories: this[list],
                },
            ];
        },

        handleChecked(data) {
            let allChecked = false;
            this.$emit("updateToolData", "gitlab", [...this.gitlabUsers], data);
            allChecked = [...this.gitlabUsers]?.length ? ([...this.gitlabUsers].findIndex((n) => !n.visited) > -1 ? false : true) : false;
            this.$emit("handleChecked", allChecked);
        },

        convertToCSV(arr) {
            return convertToCSV(arr);
        },

        csvFileName(query, section) {
            return csvFileName(this.tool, query, section);
        },

        setInput() {
            this.searchType = "email";
            this.searchKeyword = this.selectedItem.value;
            // this.search();
        },

        setTheCustomInput(value) {
            this.searchType = "email";
            this.searchKeyword = value;
            this.selectedItem = {
                value: value,
            };
            // this.search();
        },

        startLoader(section) {
            this.loaderController[section].success = false;
            this.loaderController[section].loading = true;
        },
        successLoader(section) {
            this.loaderController[section].success = true;
            this.loaderController[section].loading = false;
        },
        resetLoader(section) {
            this.loaderController[section].success = false;
            this.loaderController[section].loading = false;
        },
        async search() {
            this.showResult = false;
            this.searchClicked = true;
            const emailRegex = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
            if (this.searchKeyword && emailRegex.test(this.searchKeyword.trim())) {
                if (this.isMonitoringTab) {
                    this.monitoringSearch();
                    return;
                }
                this.getGitlab();
                await this.setSearchedUser(this.searchKeyword);
                this.SET_ALL_TOOLS_DATA({...this._data, "tool-name": "gitlab-tool"});
            } else {
                this.$toast.error("Please enter a valid input");
            }
        },

        async monitoringSearch() {
            let sources = ["gitlab-tool"];
            let values = await monitoringSearchData(this.case_id, sources, this.$route.params.toolName, this.getMonitoringDateFilter, this.getSelectedToolQuery);
            values = values.map((val, idx) => ({...val, currIndex: idx}));
            this.allGitlab = values;
            this.csvData.gitlab = this.convertToCSV(this.allGitlab);
            this.showResult = true;
        },

        async setSearchedUser(searchKeyword) {
            const data = {
                value: searchKeyword,
                platform: "gitlab",
                val_type: this.selectedItem?.val_type || "email",
                visited: true,
            };
            let url = `research-methodology/${this.case_id}/tools/profiles`;
            let isSuccess = await setUsernamesDropdownData(url, data);
            // if (isSuccess) {
            let dataItem = this[`gitlabUsers`].find((dataItem) => {
                return dataItem.value === searchKeyword;
            });
            if (dataItem) dataItem["visited"] = true;
            else if (data?.value) {
                this[`gitlabUsers`].push(data);
                this.$emit("addCustomData", data);
            }
            this.handleChecked(data);
            // }
        },

        async getGitlab(isNext) {
            this.startLoader("gitlab");
            let data = {};
            this.allGitlab = [];
            let url = "/tools/gitlab/email";
            data = {
                email: this.searchKeyword.trim(),
            };

            this.$emit("searched", {data: {email: this.searchKeyword}, source: "gitlab"});

            await axios
                .post(url, data, {
                    headers: {
                        "x-tool-name": this.$route.params.toolName,
                        "x-visited": true,
                    },
                })
                .then((response) => {
                    if (response && response.data && response.data.data?.length) {
                        this.allGitlab = response.data.data;
                        this.csvData.gitlab = this.convertToCSV(this.allGitlab);
                        this.showResult = true;
                        this.SET_ALL_TOOLS_DATA({...this._data, "tool-name": "gitlab-tool"});
                    } else if (response) {
                        this.$toast.error("No data found");
                    }
                    this.request_id = response?.data?.query_id;
                    this.redirectTo();
                    this.$emit("searchResult", {data: this.allGitlab, source: "gitlab", searchType: "email"});

                    this.resetLoader("gitlab");
                })
                .catch((error) => {
                    this.resetLoader("gitlab");
                    let message = "Please enter a valid input";
                    if (error.response && error.response.detail && error.response.detail.length) message = error.response.detail[0].msg;
                    this.$toast.error(message);
                });
        },

        redirectTo() {
            let query = {};
            if (this.request_id)
                query = {
                    query_id: this.request_id,
                };
            this.$router.push({path: this.$route.path, query}).catch((err) => {});
        },

        redirectToQuery(dataRes) {
            //redirect to same query
            if (this.$route?.query?.query_id) {
                let {query_id} = this.$route.query;
                let selected_query = this.toolSearchQueries?.find((el) => el._id === query_id);

                this.searchKeyword = selected_query?.search;
                this.selectedItem = {
                    value: selected_query?.search,
                };
                this.selectVal = selected_query?.search;
                if (!dataRes) this.search();
            } else {
                this.redirectTo();
            }
        },
        onSelect({value}) {
            this.selectVal = value;
            this.$refs["analysis-tool-input"].search = value;
        },

        close() {
            this.searchKeyword = this.selectVal;
            this.selectedItem = {
                value: this.selectVal,
            };
            this.searchType = "email";
            this.searchKeyword = this.selectVal;
            this.$refs["analysis-tool-input"].search = this.selectVal;
        },
        handleChange(event) {
            if (event != this.selectVal) {
                this.showResult = false;
                this.searchClicked = false;
            }
            this.selectVal = event;
        },

        async handleMarkAsAllDone(event) {
            const visited = event.target.checked;
            this.allGitlab.forEach((result, index) => {
                this.allGitlab[index].changes_viewed = visited;
            });
            this.$forceUpdate();
            await rawAxios.post("/data-monitoring/mark-monitered-data-visited/query/all", {
                sources: ["gitlab-tool"],
                mark_all_as_visited: visited,
                query_id: this.getSelectedToolQuery.query_id,
            });
        },
        async handleDone({target}, index) {
            let doc_id = this.allGitlab[index].monitoring_id;
            let sources = ["gitlab-tool"];
            this.allGitlab[index].changes_viewed = !this.allGitlab[index].changes_viewed;
            this.$forceUpdate();
            await rawAxios.post("/data-monitoring/mark-monitered-data-visited", {
                sources,
                doc_id,
                visited: target.checked,
            });
        },
        async handleMonitoring(index) {
            let card_source = "gitlab-tool";
            let card_id = this.allGitlab[index].doc_id;
            this.allGitlab[index].monitoring_status = !this.allGitlab[index].monitoring_status;
            let status = this.allGitlab[index].monitoring_status;
            this.$forceUpdate();
            await rawAxios.post("/data-monitoring", {
                product_name: this.getProduct.name,
                product_id: this.getProduct.key,
                case_id: this.case_id,
                query: this.getSelectedToolQuery,
                tool: this.$route.params.toolName,
                frequency: 7,
                monitoring_type: "card",
                inherit_product_settings: false,
                inherit_product_id: "",
                card_id,
                card_source,
                status: status ? "enabled" : "disabled",
            });
        },
        getFormattedTime(ts) {
            let date = DateTime.fromISO(ts);
            return date.toFormat("dd MMM yyyy");
        },
    },
};
